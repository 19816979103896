import React, { Component } from 'react'
import { Link } from "gatsby";

import client from "../../client"
import { urlFor } from '../../utils/image-builder'
import groq from 'groq'
import BlockContent from '@sanity/block-content-to-react'
import getYouTubeId from 'get-youtube-id'
import YouTube from 'react-youtube'
import Vimeo from '@u-wave/react-vimeo';
import moment from 'moment';
import SEO from '../seo'

import Header from '../Header/Header'

import Gallery from "react-photo-gallery";



import "../Header/Header.css"
import "../Article/Article.css"
import "./OriginalContent.css"

/**
 *  Article page component
 *  @version 1.1
 *  @author Nadia Mayangputri
 */ 
export default class OriginalContent extends Component {
    constructor(props) {
        super(props);
        
        // Get slug by removing parent directory in pathname
        var slug = props.location.pathname.replace('/original-content/', '')
        this.state = {
            // Article slug fetched by getting the parameters in the url - the slug is used to query the specific title
            slug: slug,
            // Where the data is stored
            originalContent: {},
        }

    }

    
    componentDidMount() {
        
        // Use Sanity's query laguage (GROQ) to query the selected post from Sanity's data store
        const query = groq`*[_type == "originalContent" && slug.current == "${this.state.slug}"][0]
        {_id, title, slug, mainImage, imagesGallery,  "category": category->title, keywords, languages, themes, "genre": genre[]->title, format,creator, writer, 
            director, producer, cast, animationStudio, prodTime, prodBudget, Summary, "developmentStage": developmentStage->title,
            fileAttachment1, attachmentTitle1, teaser, "attachmentURL1": fileAttachment1.asset->url}`
        
        // Fetch data on the article, storing it on this.state.post so that the page re-renders      
        client.fetch(query)
            .then(data => {
                this.setState({originalContent: data})
            })
            .catch(err => console.log(err))

        
        

    }


    render() {

        let originalContent = this.state.originalContent;
        let image;
        let imageGallery = [];
        let attachmentTitle = "Downloadable File";
        let filesContainer;

        console.log(originalContent.title)

        // Make sure the article data and repective images and files exist to avoid undefined errors before setting variables
        if(originalContent.mainImage !== undefined) {
            image = originalContent.mainImage.asset._ref
        }


        // use gallery images if available
        if(originalContent.imagesGallery !== undefined) {

            if(originalContent.imagesGallery.length == 1) {
                for(let i = 0; i < 3; i++) {
                    imageGallery.push({
                        src: urlFor(originalContent.imagesGallery[0].asset._ref),
                        width: 4,
                        height: 3
                    })
                } 
            } else {
                originalContent.imagesGallery.forEach((img) => {
                    imageGallery.push({
                        src: urlFor(img.asset._ref),
                        width: 4,
                        height: 3
                    })
                });
            }
            
        // if there is only 1 or no gallery images uploaded use main image
        } else {
            if(image !== undefined) {
                for(let i = 0; i < 3; i++) {
                    imageGallery.push({
                        src: urlFor(image),
                        width: 4,
                        height: 3
                    })
                } 
            }
        }

        if(originalContent.attachmentTitle1 !== undefined) {
            attachmentTitle = originalContent.attachmentTitle1
        } 

        // Only show files if there is a file uploaded
        if(originalContent.attachmentURL1 !== undefined) { 
            filesContainer = 
            <div>
                <h4>Files</h4>
                <p><a href={`${originalContent.attachmentURL1}?dl=`}>{attachmentTitle}</a></p>
            </div>
        } else {
            filesContainer = <div></div>
        }

        const BlockRenderer = props => {
            const {style = 'normal'} = props.node;
      
            if (/^h\d/.test(style)) {
                const level = style.replace(/[^\d]/g, '')
                return React.createElement(style, { className: `heading-${level}`}, props.children)
            }
            
            if (style === 'blockquote') {
                return <blockquote className="quote"> {props.children}</blockquote>
            }
            
            // Fall back to default handling
            return BlockContent.defaultSerializers.types.block(props)
          }

        // Specifies the React components to use for rendering content
        const serializers = {
            // Youtube and Vimeo embed
            types: {
                block: BlockRenderer
              },
            marks: {
              // Settings for links to CMS items, the link should open the page of the cms item
              internalLink: ({mark, children}) => {
                const {slug = {}} = mark
                const href = `/${slug.current}`
                return <a href={href}>{children}</a>
              },
              link: ({mark, children}) => {
                const { blank, href } = mark
                return blank ?
                  <a href={href} target="_blank" rel="noopener noreferrer">{children}</a>
                  : <a href={href}>{children}</a>
              }
            }
          }
          
        return(
            <div style={{marginTop: "80px"}}>

                <div className="image-gallery" onContextMenu={(e) => e.preventDefault()}>
                    <Gallery photos={imageGallery} />
                </div>
                
                
                <div className="main-container article-container original-content-container">
                    
                    <SEO 
                    title={originalContent.title} 
                    keywords={originalContent.keywords}/>

                    <div className="breadcrumbs">
                        
                        <Link to='/original-content' style={{ textDecoration: 'none', color: "black"}}>
                            &lt; Back to Original Content
                        </Link> 
                    </div>

                
                    <div className="main-content">
                        <div>
                            <div className="article-header">
                                <h1>{originalContent.title}</h1>
                            </div>
                            
                        
                            
                        </div>

                        <div className="vimeo-container">
                            {originalContent.teaser != null ? <Vimeo video={originalContent.teaser} responsive={true}/> : null}
                        </div>

                        {originalContent.teaser == null && originalContent.imagesGallery == undefined ? 
                        <img src={`${urlFor(image)}`} className="article-image" onContextMenu={(e) => e.preventDefault()}></img> : null}
                        

                        <div className="article-content original-content-content">
                            <div className="info">
                                {originalContent.genre != null ? <p><strong>Genre: </strong> 
                                {originalContent.genre.map(genre => {
                                        return(<span className="tag">{genre}</span>)
                                    })}
                                </p> : null}
                                {originalContent.themes != null ? <p><strong>Themes: </strong>{originalContent.themes}</p> : null}
                                {originalContent.languages != null ? <p><strong>Languages: </strong>{originalContent.languages}</p> : null}
                                {originalContent.format != null ? <p><strong>Format: </strong>{originalContent.format}</p> : null}
                                {originalContent.animationStudio != null ? <p><strong>Animation Studio: </strong>{originalContent.animationStudio}</p> : null}
                                {originalContent.creator != null ? <p><strong>Creator: </strong>{originalContent.creator}</p> : null}
                                {originalContent.writer != null ? <p><strong>Writer: </strong>{originalContent.writer}</p> : null}
                                {originalContent.director != null ? <p><strong>Director: </strong>{originalContent.director}</p> : null}
                                {originalContent.producer != null ? <p><strong>Producer: </strong>{originalContent.producer}</p> : null}
                                {originalContent.casts != null ? <p><strong>Confirmed Casts: </strong>{originalContent.casts}</p> : null}
                                
                            </div>
                            <div className="summary">
                            
                                <BlockContent
                                blocks={originalContent.Summary}
                                serializers={serializers}
                                // imageOptions={{ w: 320, h: 240, fit: 'max' }}
                                {...client.config()}
                                />
                                {originalContent.developmentStage != null ? <p><strong>Development Stage: </strong>{originalContent.developmentStage}</p> : null}

                                {originalContent.prodTime != null ? <p><strong>Anticipated Production Time: </strong>{originalContent.prodTime}</p> : null}
                                {originalContent.prodBudget != null ? <p><strong>Production Budget: </strong>{originalContent.prodBudget}</p> : null}

                                {/* {filesContainer} */}
                            </div>

                            
                            
                        </div>
                        
                        
                        {originalContent.imagesGallery != null ?
                        <div className="image-gallery-mobile" onContextMenu={(e) => e.preventDefault()}>
                            <Gallery photos={imageGallery} />
                        </div> : null}
                        

                    </div>
                </div>
            </div>
        )
    }
}