import React from "react"

import Layout from "../layout"

import OriginalContent from "./OriginalContent"

const OriginalContentPage = (props) => {

  return(
  <Layout >
    <OriginalContent location={props.location}/>
  </Layout>
  )
}

export default OriginalContentPage
